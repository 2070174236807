import styled from 'styled-components';

/**
 * Error Status Message
 * @param {object} props
 * @param {number} statusCode 에러 상태 코드
 */
const ErrorStatusMessage = ({ statusCode }) => {
  return (
    <Wrapper>
      <Message>{statusCode} | Error Page</Message>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Message = styled.h1`
  font-size: 21px;
  margin: 0;
`;

export default ErrorStatusMessage;
