// components
import ErrorStatusMessage from 'components/atoms/messages/ErrorStatus';

const ErrorPage = ({ statusCode }) => {
  // useEffect(() => {
  //   request
  //     .put('/next/api/v1/logging', {
  //       type: 'ERROR',
  //       statusCode,
  //       message: errorMessage ? errorMessage : '에러 메세지가 없습니다.',
  //     })
  //     .catch(() => {});
  // }, [statusCode, errorMessage]);

  return (
    <>
      <ErrorStatusMessage statusCode={statusCode} />
    </>
  );
};

/* 
	rawHeaders
*/
export async function getServerSideProps({ res, err }) {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return {
    props: {
      statusCode,
      errorMessage: `${err}`,
    },
  };
}

export default ErrorPage;
